<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('loyalty.budgetcaps')" class="connect">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{ $t('loyalty.Budget_caps') }}</span>
            </CCol>             
          </CRow>
        </CCardHeader>
        <CCardBody class="pt-0 pb-0">
          <CRow v-if="budgetCapsLoading">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-else class="pb-0">
            <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
              <div class="h-100 d-flex align-items-center">
                <span>{{$t('loyalty.Budget_cap_organization')}}</span>
              </div>
            </CCol>
            <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
              <quick-edit type="number" :step="1000" :min="0" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="budgetCaps.organization.budget" @input="setBudgetCap('organization')">
                <h2 v-if="budgetCaps.organization.budget" class="m-0 display-flex align-items-center">{{'€' + formatToCurrency(budgetCaps.organization.budget)}}<i class="icon edit fas fa-pen"/></h2>
                <span v-else>{{$t('loyalty.Set_budget_cap_for_organization')}}<i class="icon edit fas fa-pen"/></span>                
              </quick-edit>
            </CCol>
          </CRow>
          <CRow class="pb-0">
            <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
              <div class="h-100 d-flex align-items-center">
                <span>{{$t('loyalty.Budget_cap_managers')}}</span>
              </div>
            </CCol>
            <CCol cols="6" xl="6" lg="6" md="6" sm="6" class="pt-0">
              <quick-edit type="number" :step="1000" :min="0" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="budgetCaps.managers.budget" @input="setBudgetCap('managers')">
                <h2 v-if="budgetCaps.managers.budget" class="m-0 display-flex align-items-center">{{'€' + formatToCurrency(budgetCaps.managers.budget)}}<i class="icon edit fas fa-pen"/></h2>
                <span v-else>{{$t('loyalty.Set_budget_cap_for_managers')}}<i class="icon edit fas fa-pen"/></span>
              </quick-edit>
            </CCol>
          </CRow>          
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>
<script>
import axios from 'axios'
import noPermission from '@/components/common/noPermission.vue';
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'BudgetCaps',
  components: {
    noPermission,
    QuickEdit
  },
  data() {
    return {
      modules: {},      
      platformPermissions: [],
      platformPermissionsLoaded: false,
      budgetCapsLoading: false,
      budgetCapsLoaded: false,
      budgetCaps: {
        organization: {
          loyalty_budget_cap_id_external: null,
          budget: null
        },
        managers: {
          loyalty_budget_cap_id_external: null,
          budget: null
        }        
      }     
    }
  },
  methods: {
    getBudgetCaps() {
      if(this.budgetCapsLoaded === false) this.budgetCapsLoading = true;      
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/budget-caps')
        .then(res => {
          this.budgetCaps = res.data.data;
          this.budgetCapsLoading = false;
          this.budgetCapsLoaded = true;
        })
        .catch(err => {
          console.error(err);
          this.budgetCapsLoading = false;
        });
    },
    setBudgetCap(type) {
      const budgetCapData = this.budgetCaps[type];
      if (budgetCapData.loyalty_budget_cap_id_external) {
        this.updateBudgetCap(type, budgetCapData);
      } else {
        this.createBudgetCap(type, budgetCapData);
      }
    },
    createBudgetCap(type, budgetCapData) {
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/budget-cap', { budgetCapData })
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('loyalty.Budget_cap_created'), type: 'is-success', duration: 2000 });
          this.budgetCaps[type].loyalty_budget_cap_id_external = res.data.data.loyalty_budget_cap_id_external;
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
    },
    updateBudgetCap(type, budgetCapData) {
      axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/budget-cap/' + budgetCapData.loyalty_budget_cap_id_external, { budgetCapData })
        .then(() => {
          this.$buefy.toast.open({ message: this.$t('loyalty.Budget_cap_updated'), type: 'is-success', duration: 2000 });
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
        .then(res => {      
          this.platformPermissions = res.data.data;
          this.platformPermissionsLoaded = true;
        })
        .catch(err => {
          console.error(err); 
        });
    },
    checkPermission(permissionTag) {
      return this.platformPermissions.includes(permissionTag);
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
        .then(res => {      
          this.modules = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
    },
    formatToCurrency(data) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0 }).format(parseInt(data));
    }    
  },
  mounted() {
    this.checkModules();
    this.getPlatformPermissions();
    this.getBudgetCaps();
  }
}
</script>